import type { AuthContextProps } from 'react-oidc-context';
import { useAuth } from 'react-oidc-context';

import { APP_CONFIG } from 'App.config';

export type UserProfile = {
  name?: string;
  email?: string;
  userId?: string;
};

type SelectedAuthContextProps = Pick<
  AuthContextProps,
  'signinRedirect' | 'removeUser' | 'error' | 'isLoading' | 'signoutRedirect'
>;

export type Account = Readonly<
  {
    userInfo?: UserProfile;
    isRegistered?: boolean;
    // This flag checks if "embio" license is present in claim
    isAuthorized?: boolean;
    accessToken?: string;
  } & SelectedAuthContextProps
>;

const checkForLicense = (license?: string) => {
  return license === APP_CONFIG.embioLicense;
};

// Custom hook to extract user profile and authorization type from useAuth hook
const useAuthData = (): Account => {
  const { isLoading, isAuthenticated, error, user, signinRedirect, signoutRedirect, removeUser } =
    useAuth();

  const defaultUserAuthData: Account = {
    isLoading,
    error,
    signinRedirect,
    removeUser,

    signoutRedirect,
  };

  if (!isAuthenticated) {
    return {
      ...defaultUserAuthData,
    };
  }

  const userProfile = user?.profile;

  const userProfileData = {
    name: userProfile?.name,
    email: userProfile?.email,
    userId: userProfile?.auth0_id as unknown as string,
  };

  const userAuthData = {
    userInfo: userProfileData,
    isRegistered: isAuthenticated,
    isAuthorized: checkForLicense(userProfile?.license as string),
    accessToken: user?.access_token,
  };

  return {
    ...userAuthData,
    isLoading,
    error,
    signinRedirect,
    signoutRedirect,
    removeUser,
  };
};

export default useAuthData;
