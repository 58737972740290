import React from 'react';
import { Redirect, Switch } from 'react-router-dom';

import LazyLoad from 'components/LazyLoad';
import content from 'constants/content';
import CookiesPolicy from 'pages/CookiesPolicy';
import LandingPage from 'pages/LandingPage';
import Search from 'pages/Search';
import AppRoute from './AppRoute';

type Props = {
  isAuthorized?: boolean;
};

const ResultModule = React.lazy(() => import('pages/Result'));
const ReferenceModule = React.lazy(() => import('pages/Reference'));
const SavedArticlesModule = React.lazy(() => import('pages/SavedArticles'));
const GenesAnalysisModule = React.lazy(() => import('pages/GenesAnalysis'));
const NetworkModule = React.lazy(() => import('pages/Network'));

const SearchPage = () => <Search staticContent={content.searchPage} />;
const LazyResultsPage = () => (
  <LazyLoad component={ResultModule} staticContent={content.resultPage} />
);
const LazyReferencePage = () => <LazyLoad component={ReferenceModule} />;
const LazySavedArticlesPage = () => <LazyLoad component={SavedArticlesModule} />;
const LazyGenesAnalysisPage = () => <LazyLoad component={GenesAnalysisModule} />;
const LazyNetworkModule = () => <LazyLoad component={NetworkModule} />;

const AppRouting = ({ isAuthorized = false }: Props) => {
  return (
    <Switch>
      <AppRoute isAllowed path='/legal/cookies-policy' component={CookiesPolicy} />
      <AppRoute isAllowed path='/login' component={LandingPage} />
      <AppRoute path='/search' isAllowed={isAuthorized} component={SearchPage} />
      <AppRoute path='/result' isAllowed={isAuthorized} component={LazyResultsPage} />
      <AppRoute path='/reference' isAllowed={isAuthorized} component={LazyReferencePage} />
      <AppRoute path='/saved' isAllowed={isAuthorized} component={LazySavedArticlesPage} />
      <AppRoute path='/genesAnalysis' isAllowed={isAuthorized} component={LazyGenesAnalysisPage} />
      <AppRoute path='/networks' isAllowed={isAuthorized} component={LazyNetworkModule} />
      <Redirect to='/search' />
    </Switch>
  );
};

export default AppRouting;
